.mockup-animation {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.mockup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  height: 100%;
  margin-right: 50px;
}

.mockup {
  position: absolute;
  width: 270px;
  height: auto;
  transform: translate(150%, -150%) rotate(30deg);
  opacity: 0;
  animation: slideIn 3s ease-in-out forwards;
}

.delay1 {
  top: 190px;
  right: 250px;
  animation-delay: 1.6s;
}

.delay2 {
  top: 600px;
  right: 490px;
  animation-delay: 1.5s;
}

.delay3 {
  top: 190px;
  right: -15px;
  animation-delay: 1s;
}

.delay4 {
  top: 600px;
  right: 220px;
  animation-delay: 1s;
}

.delay5 {
  top: 545px;
  right: -80px;
  animation-delay: 0.5s;
}

@keyframes slideIn {
  0% {
    transform: translate(150%, -150%) rotate(30deg);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) rotate(30deg);
    opacity: 1;
  }
}
