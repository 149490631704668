/* App.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Poppins", sans-serif; /* Updated to Poppins */
  background: #e7e7e7;
  color: #333;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1; /* Ensure main elements are above MockupAnimation */
}

/* Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
}

.navbar img {
  height: 40px;
  display: block;
  margin: 0 auto;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 85px 10px;
  flex: 1;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.text-content {
  text-align: center;
  margin-right: 20px; /* Adjust to move to the right */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible; /* Ensure overflow text is visible */
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 4rem;
  margin: 20px 0 0; /* Reduce bottom margin to move subtitle closer */
  text-shadow: 2px 2px 4px rgb(255, 255, 255); /* White shadow */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
}

.subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 2.3rem;
  margin: 5px 0 40px; /* Adjust margins to move closer to title */
  text-shadow: 2px 2px 4px rgb(255, 255, 255); /* White shadow */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
}

.app-links {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.app-link {
  margin: 0 60px;
  text-align: center;
}

.app-link h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 505;
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.942); /* White shadow */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
}

.app-link img {
  height: 60px;
}

.coverage-button {
  background-color: #00b04f;
  color: white;
  margin-top: 50px;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s; /* Smooth transform and background transition */
}

.coverage-button:hover,
.app-link img:hover {
  background-color: #008a3c;
  transform: scale(1.05);
  transition: transform 0.3s, background-color 0.3s;
}

footer {
  padding: 20px;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
  margin-top: auto;
}

footer .social-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer .social-links a {
  margin: 0 15px;
}

footer .social-links img {
  height: 40px;
  transition: transform 0.3s; /* Smooth transform transition */
}

footer .social-links img:hover {
  transform: scale(1.2); /* Slightly enlarge the icon on hover */
}

.fade-in {
  animation: fadeIn 2s ease-out;
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content ul {
  list-style-type: none;
  padding: 0;
}

.modal-content li {
  margin: 10px 0;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00b04f;
  color: white;
  border: none;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.modal-content button:hover {
  background-color: #008a3c;
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .content {
    padding: 20px 40px 10px;
  }

  .title {
    font-size: 3.5rem;
  }

  .subtitle {
    font-size: 2rem;
  }

  .app-link h2 {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 15px 20px;
  }

  .navbar img {
    margin-bottom: 10px;
  }

  .content {
    padding: 20px 20px 10px;
  }

  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.8rem;
  }

  .app-link {
    margin: 0 30px;
  }

  .app-link h2 {
    font-size: 1rem;
  }

  footer img {
    height: 30px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px 15px;
  }

  .content {
    padding: 10px 15px;
    margin-top: 40px;
  }

  .title {
    font-size: 2.5rem;
    white-space: normal; /* Allow text wrapping */
    word-wrap: break-word; /* Allow breaking words if necessary */
    overflow-wrap: break-word; /* Ensure text stays within container */
  }

  .subtitle {
    font-size: 1.5rem;
    white-space: normal; /* Allow text wrapping */
    word-wrap: break-word; /* Allow breaking words if necessary */
    overflow-wrap: break-word; /* Ensure text stays within container */
  }

  .app-links {
    flex-direction: column;
  }

  .app-link {
    margin-bottom: 20px;
    margin: 0 10px;
  }

  .app-link h2 {
    font-size: 0.9rem;
    white-space: normal; /* Allow text wrapping */
    word-wrap: break-word; /* Allow breaking words if necessary */
    overflow-wrap: break-word; /* Ensure text stays within container */
  }

  .coverage-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  footer img {
    height: 25px;
  }
}
